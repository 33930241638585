import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 988.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
		<path d="M4796 8548 c-9 -17 -15 -32 -14 -33 2 -1 18 -7 36 -13 31 -11 33 -10
45 21 13 31 13 32 -16 45 -37 15 -33 17 -51 -20z"/>
<path d="M5270 8108 c-7 -18 -15 -35 -17 -38 -2 -3 14 -10 36 -16 37 -10 39
-10 53 21 14 31 14 32 -16 45 -17 8 -33 15 -36 17 -3 2 -12 -11 -20 -29z"/>
<path d="M4840 8073 c-61 -33 -69 -35 -140 -30 -41 3 -86 8 -99 12 l-23 8 26
-41 c14 -22 28 -52 32 -66 8 -33 -6 -34 -77 -2 -233 102 -575 114 -829 30
-302 -101 -545 -299 -700 -571 -103 -179 -150 -351 -157 -573 -4 -134 -2 -168
17 -255 12 -55 24 -111 26 -125 l5 -25 -15 26 c-24 41 -65 158 -82 229 -57
252 -20 591 87 801 11 23 18 43 16 46 -13 13 -95 -138 -143 -262 -99 -260
-103 -570 -12 -840 58 -171 159 -335 288 -464 74 -75 194 -171 213 -171 5 0
-11 24 -36 53 -94 110 -201 306 -246 447 -55 174 -68 443 -31 629 58 290 219
561 440 743 55 45 174 128 184 128 3 0 29 14 58 31 73 43 272 107 387 124 53
8 141 15 196 15 160 0 384 -41 411 -76 12 -16 0 -122 -17 -138 -23 -24 -6 -25
24 -2 71 54 186 72 261 41 l36 -15 -29 52 c-41 73 -49 145 -24 221 11 31 19
57 19 56 -1 0 -30 -17 -66 -36z"/>
<path d="M5897 7170 c-137 -35 -213 -138 -191 -257 16 -83 68 -141 236 -256
72 -50 105 -80 124 -113 29 -50 43 -127 25 -138 -6 -4 -11 -14 -11 -23 0 -9
-7 -24 -15 -33 -14 -15 -12 -18 25 -30 30 -10 43 -10 53 -2 8 7 17 9 21 5 4
-4 4 0 1 9 -5 13 -2 15 13 11 27 -7 96 41 123 86 17 27 22 53 23 101 1 107
-37 157 -213 285 -64 46 -132 100 -151 120 -31 32 -34 41 -35 95 0 80 28 111
108 118 65 5 98 -10 150 -67 32 -36 77 -144 77 -187 0 -8 5 -14 10 -14 6 0 10
45 11 113 1 61 2 123 3 137 1 31 -18 43 -22 13 -5 -33 -24 -34 -92 -1 -51 24
-79 30 -149 33 -47 3 -103 0 -124 -5z m270 -802 c-3 -8 -6 -5 -6 6 -1 11 2 17
5 13 3 -3 4 -12 1 -19z"/>
<path d="M3540 7150 c0 -5 10 -10 23 -10 12 0 31 -7 42 -16 19 -14 20 -29 23
-389 4 -367 3 -374 -17 -394 -13 -13 -16 -21 -8 -21 7 0 18 5 25 12 9 9 12 9
12 0 0 -20 16 -14 29 11 l11 22 -6 -23 -6 -23 119 2 c65 2 110 5 101 7 -10 2
-20 16 -23 35 -4 18 -5 193 -3 390 3 342 4 359 22 373 11 8 30 14 43 14 13 0
23 5 23 10 0 6 -75 10 -205 10 -130 0 -205 -4 -205 -10z m307 -802 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M4370 7151 c0 -6 10 -11 23 -11 13 0 32 -6 43 -14 18 -14 19 -32 22
-393 3 -377 3 -378 -19 -395 -16 -14 -17 -18 -6 -18 9 0 23 7 31 16 15 14 16
14 16 0 0 -13 13 -16 74 -16 65 0 76 3 90 23 l17 22 -5 -22 c-3 -15 1 -23 10
-23 8 0 14 5 14 11 0 5 5 7 10 4 6 -4 8 -11 5 -16 -4 -5 15 -9 42 -8 47 1 47
1 13 9 -43 10 -63 36 -56 74 3 16 6 182 6 370 1 396 -3 379 87 380 117 1 188
-71 219 -220 21 -103 15 -363 -11 -439 -23 -68 -63 -124 -105 -146 -38 -21
-27 -26 17 -7 15 6 32 10 37 9 14 -3 98 31 141 57 22 14 57 43 78 66 116 124
141 334 58 496 -34 68 -121 137 -212 170 -60 22 -83 24 -351 28 -204 3 -288 1
-288 -7z m590 -791 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0
4 -4 4 -10z"/>
<path d="M5701 6474 c2 -139 4 -162 15 -143 7 13 11 26 9 30 -2 3 11 5 30 5
18 -1 31 -4 28 -9 -4 -7 38 -27 58 -27 6 0 -5 15 -24 33 -45 42 -72 104 -87
200 -7 42 -17 77 -22 77 -5 0 -8 -72 -7 -166z"/>
<path d="M4104 6483 c-43 -8 -71 -106 -41 -143 62 -75 207 -37 207 53 0 77
-62 111 -166 90z m147 -110 c-12 -20 -14 -14 -5 12 4 9 9 14 11 11 3 -2 0 -13
-6 -23z m-73 -60 c-10 -2 -26 -2 -35 0 -10 3 -2 5 17 5 19 0 27 -2 18 -5z"/>
<path d="M5405 6481 c-48 -21 -69 -72 -51 -127 19 -61 149 -72 194 -17 28 34
25 94 -7 124 -24 22 -104 34 -136 20z m78 -168 c-13 -2 -35 -2 -50 0 -16 2 -5
4 22 4 28 0 40 -2 28 -4z"/>
<path d="M5860 6322 c0 -15 54 -22 149 -20 l86 2 -100 6 c-55 3 -108 10 -117
14 -11 5 -18 4 -18 -2z"/>
<path d="M2390 5231 c-79 -17 -135 -54 -166 -112 -17 -30 -19 -62 -22 -248 -3
-180 -1 -221 13 -257 18 -48 64 -93 122 -121 36 -17 145 -18 2603 -18 l2565 0
48 22 c32 15 46 27 41 34 -4 7 -3 9 3 6 14 -9 49 31 68 80 25 62 19 441 -8
493 -32 63 -66 94 -103 94 -19 0 -29 3 -23 7 8 4 7 9 -2 15 -17 11 -5089 15
-5139 5z m167 -17 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m80
0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6
6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2
13 -5z m80 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 0 c-3
-3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12 -4 -19 -1
-8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11
1 17 -2 13 -5z m80 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m80 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12 -4 -19 -1 -8 3
-5 6 6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17
-2 13 -5z m80 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 0
c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6
6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2
13 -5z m80 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 0 c-3
-3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12 -4 -19 -1
-8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11
1 17 -2 13 -5z m80 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m80 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12 -4 -19 -1 -8 3
-5 6 6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17
-2 13 -5z m80 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 0
c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6
6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2
13 -5z m80 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 0 c-3
-3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12 -4 -19 -1
-8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11
1 17 -2 13 -5z m80 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m80 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12 -4 -19 -1 -8 3
-5 6 6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17
-2 13 -5z m80 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 0
c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6
6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2
13 -5z m80 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 0 c-3
-3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12 -4 -19 -1
-8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11
1 17 -2 13 -5z m80 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m80 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12 -4 -19 -1 -8 3
-5 6 6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17
-2 13 -5z m80 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 0
c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6
6 6 11 1 17 -2 13 -5z m80 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2
13 -5z m93 -4 c18 -12 2 -12 -25 0 -13 6 -15 9 -5 9 8 0 22 -4 30 -9z m-5160
-54 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z
m3977 -52 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-4040 -56
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m1068 -162 c-7 -158 -3
-186 26 -186 17 0 19 8 19 95 0 77 -3 96 -16 103 -27 15 -6 32 41 32 24 0 47
5 51 11 4 8 9 7 14 -2 13 -20 201 -15 216 6 10 14 12 13 17 -7 3 -13 6 -70 7
-128 1 -99 9 -128 32 -114 13 8 9 62 -4 67 -8 3 -6 6 5 6 9 1 17 9 18 19 0 9
8 -3 16 -28 22 -62 63 -93 63 -47 0 13 -18 39 -45 66 -36 34 -45 50 -45 77 0
58 72 98 133 75 17 -7 28 -7 32 -1 4 6 1 10 -6 10 -7 0 -9 3 -6 6 4 4 14 1 23
-6 13 -12 17 -11 25 1 7 12 9 11 9 -3 0 -9 -6 -18 -12 -21 -10 -3 -10 -9 -1
-25 9 -16 9 -24 -2 -38 -16 -21 -20 -58 -6 -49 5 3 13 0 17 -6 4 -7 3 -9 -3
-6 -6 4 -8 -11 -5 -42 l4 -48 -46 -22 c-33 -17 -54 -21 -77 -16 -22 5 -29 4
-25 -4 4 -6 2 -11 -3 -11 -6 0 -11 7 -11 15 0 18 -17 20 -22 3 -3 -7 -9 -8
-18 -3 -7 4 -46 7 -85 6 -50 -2 -76 2 -81 10 -4 7 -2 15 4 17 16 5 17 202 1
202 -5 0 -7 -5 -4 -10 23 -37 -41 -82 -80 -56 -21 14 -22 14 -23 -55 -2 -65
-1 -69 25 -83 21 -11 23 -15 10 -16 -10 0 -16 -4 -13 -9 3 -5 -3 -11 -14 -14
-11 -3 -18 -1 -14 4 5 9 -37 11 -181 10 -92 0 -87 0 -81 -10 13 -20 -80 11
-101 34 -42 47 -41 163 1 212 19 22 30 26 72 25 l50 -2 -4 40 c-2 21 -11 45
-20 52 -13 11 -6 15 44 23 86 16 87 15 79 -159z m343 168 c13 -4 24 -16 27
-30 10 -49 14 -79 9 -66 -8 16 -19 15 -38 -4 -9 -8 -16 -11 -16 -6 0 5 -13 12
-29 15 -37 8 -48 39 -27 72 17 25 33 30 74 19z m2642 -9 c17 -9 30 -12 30 -9
0 4 3 5 6 2 10 -11 -24 -75 -42 -80 -24 -6 -84 10 -84 21 0 5 -3 16 -6 24 -7
18 29 57 51 57 9 0 29 -7 45 -15z m-2123 -11 c-3 -3 -12 -4 -19 -1 -8 3 -5 6
6 6 11 1 17 -2 13 -5z m590 -16 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12
1 -19z m560 16 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m58 -14
c13 0 27 -4 30 -10 10 -16 25 -12 25 7 0 11 3 13 9 8 6 -6 11 -40 13 -77 2
-36 1 -65 -1 -62 -2 2 -9 0 -15 -6 -8 -6 -12 0 -14 23 -3 46 -33 82 -68 82
-24 0 -30 -5 -32 -26 -2 -20 4 -32 25 -48 15 -11 33 -24 38 -28 6 -5 18 -14
28 -22 16 -13 20 -13 28 0 8 10 9 9 4 -8 -4 -13 -1 -25 8 -30 19 -11 27 -91
10 -97 -7 -3 -10 -12 -7 -21 4 -9 3 -15 -2 -14 -5 1 -22 -4 -39 -11 -27 -11
-71 -14 -172 -8 l-33 1 0 74 c0 58 3 73 15 73 9 0 15 -9 15 -22 0 -31 22 -87
36 -92 9 -3 11 1 7 12 -4 10 2 7 14 -8 17 -20 23 -22 36 -12 27 22 27 51 0 82
-14 17 -30 30 -36 30 -7 0 -6 -5 3 -11 13 -9 13 -10 0 -6 -8 2 -15 9 -15 15 0
19 -16 27 -26 12 -9 -13 -10 -12 -6 2 3 9 -3 24 -13 33 -22 20 -35 73 -27 108
8 28 56 57 96 57 18 0 31 5 32 13 0 10 2 10 6 0 2 -7 15 -13 28 -13z m-1208
-5 c44 0 79 3 77 6 -5 8 71 -21 102 -38 71 -41 74 -242 3 -260 -10 -3 -19 -9
-19 -13 0 -12 -59 -33 -91 -32 -19 0 -29 -4 -29 -14 0 -8 -4 -14 -10 -14 -5 0
-7 6 -5 13 4 9 -18 12 -90 12 -52 0 -95 1 -95 3 0 2 14 8 30 13 l30 11 0 143
c0 139 -1 144 -22 151 l-21 6 24 13 c13 7 26 10 29 7 3 -4 42 -7 87 -7z
m-1117 -5 c0 -6 -7 -13 -15 -16 -12 -5 -15 -31 -15 -149 0 -138 1 -144 21
-150 39 -13 0 -23 -87 -24 -79 -1 -122 14 -75 26 20 5 21 11 21 148 0 137 -1
143 -21 148 -12 3 -18 10 -14 16 8 14 185 15 185 1z m1748 -43 c2 -28 8 -37
22 -37 30 0 32 -17 2 -23 l-27 -5 3 -95 c3 -88 5 -94 23 -90 10 3 19 13 19 22
0 20 46 75 58 67 11 -6 72 30 72 44 0 16 -14 11 -28 -10 -14 -24 -54 -26 -76
-5 -8 8 -19 15 -25 15 -6 0 -11 5 -11 11 0 5 4 8 9 5 5 -3 12 5 15 19 4 14 14
25 24 26 14 0 14 2 -3 8 -14 6 -3 10 40 14 120 14 149 -15 150 -146 0 -49 4
-76 10 -73 14 5 17 -32 4 -39 -17 -11 -89 -8 -103 4 -10 8 -16 9 -21 1 -9 -15
-82 -12 -97 4 -10 11 -17 10 -43 -5 -41 -26 -71 -24 -101 6 -14 14 -30 25 -37
26 -9 0 -9 2 1 6 19 7 17 183 -2 183 -24 0 -30 21 -8 27 13 3 22 15 24 32 2
21 9 27 33 29 17 2 34 8 40 13 17 17 30 3 33 -34z m-1943 13 c3 -5 1 -10 -4
-10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m2520 -10 c4 -6 -1 -7
-11 -3 -11 4 -15 3 -10 -5 3 -6 2 -13 -4 -17 -5 -3 -10 0 -10 8 0 25 23 36 35
17z m-1815 -4 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10
-2 10 -4z m-523 -53 c-3 -10 -5 -2 -5 17 0 19 2 27 5 18 2 -10 2 -26 0 -35z
m338 37 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z
m1628 3 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m1480 0 c-7
-2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m105 -7 c-7 -8 -15 -12 -17
-11 -5 6 10 25 20 25 5 0 4 -6 -3 -14z m82 0 c-7 -7 -13 -7 -20 0 -6 6 -3 10
10 10 13 0 16 -4 10 -10z m-3193 -2 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1
17 -2 13 -5z m560 -41 c-3 -10 -5 -2 -5 17 0 19 2 27 5 18 2 -10 2 -26 0 -35z
m413 37 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z
m180 0 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11
-10z m217 4 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m1316 -1
c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m277 0 c0 -5 20 -8 45
-9 50 -1 80 -21 87 -59 5 -25 -23 -65 -45 -65 -40 1 -57 13 -58 44 0 26 -2 28
-9 11 -16 -37 -11 -90 10 -113 24 -26 43 -28 60 -7 17 21 37 19 43 -5 3 -11 2
-18 -2 -15 -5 2 -14 -6 -21 -19 -19 -36 -80 -53 -126 -35 -54 21 -77 57 -77
121 0 30 6 65 13 78 15 28 48 60 62 60 5 0 6 5 3 10 -3 6 -1 10 4 10 6 0 11
-3 11 -7z m117 1 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m-1934 -32 c16 -17 19 -34 19 -104 0 -63 4 -87 16 -101 14 -16 14 -19 2 -27
-8 -6 -23 -10 -32 -10 -9 0 -20 -4 -23 -10 -3 -5 -11 -10 -17 -10 -6 0 -8 3
-5 7 9 8 -35 31 -47 25 -4 -2 -3 -8 3 -12 7 -3 -9 -5 -35 -4 -54 3 -84 26 -84
63 1 35 16 52 73 78 43 21 47 26 47 57 0 19 -4 37 -8 40 -5 3 -6 -9 -4 -26 5
-35 -16 -58 -55 -58 -32 0 -51 40 -35 75 21 46 144 57 185 17z m1060 15 c-3
-4 8 -20 24 -34 15 -14 22 -22 15 -19 -9 5 -12 -2 -10 -26 2 -26 -1 -33 -14
-32 -10 1 -18 -3 -18 -10 0 -8 -2 -8 -8 0 -8 13 -82 6 -82 -8 0 -5 4 -7 9 -4
4 3 6 -5 4 -17 -7 -38 19 -52 62 -31 20 9 39 14 42 10 11 -11 -28 -71 -55 -84
-82 -39 -162 21 -162 122 0 76 27 119 85 137 28 9 114 6 108 -4z m627 -99 c0
-133 -1 -121 16 -131 28 -16 2 -27 -66 -27 -65 0 -99 13 -67 26 11 4 15 27 16
88 2 86 -4 116 -23 116 -13 0 -38 -51 -40 -85 0 -13 -4 -21 -7 -19 -9 5 -20
-56 -12 -70 3 -6 2 -8 -2 -3 -5 4 -15 -7 -22 -24 -10 -25 -17 -30 -40 -27 -27
3 -37 16 -61 78 -5 14 -17 42 -26 63 -9 21 -16 45 -16 55 -1 12 -5 9 -15 -9
-8 -15 -21 -29 -30 -33 -19 -7 -61 9 -68 26 -3 7 -6 -22 -6 -65 -1 -74 0 -78
25 -89 23 -11 24 -13 7 -19 -28 -11 -151 -10 -158 1 -3 6 1 13 9 16 23 9 23
199 1 208 -32 12 -12 25 43 28 31 2 166 3 299 3 l243 0 0 -107z m444 98 c19
-8 36 -22 39 -33 15 -54 17 -73 7 -73 -6 0 -8 -4 -5 -10 3 -5 2 -10 -4 -10 -5
0 -12 4 -15 8 -2 4 -23 6 -45 4 -35 -4 -41 -7 -41 -27 0 -33 27 -57 54 -49 11
4 23 11 24 15 2 5 10 9 18 9 8 0 14 4 14 10 0 5 7 7 15 4 10 -4 15 0 15 12 0
30 20 -5 20 -36 0 -20 2 -22 10 -10 8 12 10 9 10 -11 0 -32 25 -45 39 -21 7
13 -1 28 -40 69 -60 63 -65 98 -20 136 26 22 38 25 98 22 56 -2 68 -5 67 -19
0 -9 -1 -32 -2 -51 -2 -38 -17 -47 -27 -15 -4 11 -11 18 -16 15 -5 -3 -6 0 -3
8 3 8 -2 19 -10 26 -16 13 -36 7 -36 -10 0 -15 71 -79 87 -79 8 0 12 -4 8 -10
-3 -5 0 -18 7 -29 10 -16 9 -26 -4 -55 -22 -44 -79 -65 -133 -49 -20 7 -37 7
-40 2 -7 -10 -45 11 -45 24 0 4 4 6 9 3 5 -3 9 10 9 29 0 44 -10 45 -26 1 -25
-68 -131 -85 -183 -30 -21 22 -24 36 -24 99 0 64 3 77 24 99 22 24 64 43 99
45 7 1 28 -5 46 -13z m-1254 -6 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8
10 11 10 2 0 4 -4 4 -10z m1110 0 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8
10 11 10 2 0 4 -4 4 -10z m-3568 -148 c2 -34 0 -62 -4 -62 -5 0 -7 42 -6 93 1
94 5 82 10 -31z m1011 -55 c8 -6 8 -7 -3 -4 -12 3 -15 22 -16 88 l0 84 3 -80
c2 -46 9 -83 16 -88z m624 101 c-2 -13 -4 -3 -4 22 0 25 2 35 4 23 2 -13 2
-33 0 -45z m813 58 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6
0 10 -2 10 -4z m697 -118 c-2 -24 -4 -5 -4 42 0 47 2 66 4 43 2 -24 2 -62 0
-85z m-1100 80 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-657
-23 c7 -9 8 -15 2 -15 -5 0 -12 7 -16 15 -3 8 -4 15 -2 15 2 0 9 -7 16 -15z
m2067 -40 c-1 -22 -4 -30 -9 -22 -6 12 -1 57 7 57 2 0 3 -16 2 -35z m553 21
c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z
m-1425 -36 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11
-10z m-290 -10 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4
11 -10z m612 -12 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-222
-17 c-3 -6 -11 -11 -16 -11 -5 0 -4 6 3 14 14 16 24 13 13 -3z m1175 5 c0 -3
-4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-3366 -32
c3 -8 2 -12 -4 -9 -6 3 -10 10 -10 16 0 14 7 11 14 -7z m2623 -16 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-3302 -18 c3 -5 2 -10 -4 -10 -5
0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z m3058 3 c-7 -2 -19 -2 -25
0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m877 3 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1
-10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-3827 -13 c-7 -2 -19 -2 -25 0 -7 3
-2 5 12 5 14 0 19 -2 13 -5z m687 3 c0 -2 -16 -6 -35 -8 -19 -2 -35 0 -35 4 0
4 16 8 35 8 19 0 35 -2 35 -4z m55 -14 c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10
-3 15 -9 12 -12z m448 11 c-13 -2 -35 -2 -50 0 -16 2 -5 4 22 4 28 0 40 -2 28
-4z m1127 -3 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
-4 10 -10z m155 0 c3 -6 -1 -7 -9 -4 -18 7 -21 14 -7 14 6 0 13 -4 16 -10z
m342 4 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m513 2 c0 -2
-11 -6 -25 -8 -13 -3 -22 -1 -19 3 5 9 44 13 44 5z m197 -12 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m430 0 c-3 -3 -12 -4 -19 -1 -8 3 -5
6 6 6 11 1 17 -2 13 -5z m-4530 -90 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1
17 -2 13 -5z m5193 -28 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25
6z"/>
<path d="M3155 4868 c-3 -18 -5 -60 -3 -93 2 -45 7 -60 18 -60 12 0 15 16 15
85 0 47 5 88 10 92 6 4 0 8 -12 8 -17 0 -23 -7 -28 -32z m25 -27 c0 -6 -4 -13
-10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z"/>
<path d="M3810 4887 c0 -7 16 -28 36 -47 l36 -34 -7 35 c-4 18 -12 40 -17 47
-14 16 -48 16 -48 -1z"/>
<path d="M4178 4964 c-2 -16 -2 -81 0 -146 3 -127 11 -142 57 -112 38 25 56
138 35 214 -14 50 -22 59 -57 66 -29 6 -32 4 -35 -22z m32 12 c0 -3 -4 -8 -10
-11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m47 -153 c-2 -16 -4
-3 -4 27 0 30 2 43 4 28 2 -16 2 -40 0 -55z"/>
<path d="M4936 4895 c4 -8 11 -15 16 -15 6 0 5 6 -2 15 -7 8 -14 15 -16 15 -2
0 -1 -7 2 -15z"/>
<path d="M4940 4769 c-7 -11 -9 -27 -6 -35 10 -25 21 -15 22 21 1 41 -1 42
-16 14z"/>
<path d="M4519 4766 c-9 -18 -9 -30 -2 -37 7 -7 12 0 17 22 3 18 4 34 1 37 -2
2 -10 -7 -16 -22z"/>
<path d="M5596 4898 c-15 -49 -14 -58 7 -58 18 0 19 2 7 10 -10 6 -11 10 -2
10 6 0 12 5 12 11 0 5 -4 8 -9 5 -5 -4 -7 3 -4 14 6 21 -5 28 -11 8z"/>
<path d="M6061 4901 c-9 -6 -9 -15 -2 -32 19 -47 24 -64 23 -71 -1 -5 2 -8 7
-8 9 0 21 47 21 81 0 22 -31 41 -49 30z m36 -42 c1 -19 1 -20 -4 -4 -3 11 -10
24 -17 28 -7 6 -6 7 4 4 8 -2 16 -14 17 -28z"/>
<path d="M6687 4893 c-13 -12 -7 -53 7 -53 10 0 13 8 10 27 -6 32 -8 35 -17
26z"/>
<path d="M4655 4054 c-22 -2 -85 -9 -140 -14 -205 -21 -433 -77 -632 -156
-202 -80 -247 -121 -191 -174 50 -47 146 -41 273 17 273 125 536 177 885 177
347 -1 652 -64 920 -193 115 -55 250 -35 250 38 0 35 -5 39 -105 89 -177 88
-425 159 -695 198 -100 15 -478 27 -565 18z"/>
<path d="M4675 3779 c-134 -11 -198 -23 -349 -64 -139 -38 -287 -102 -317
-137 -21 -24 -17 -55 10 -75 46 -35 159 -29 251 14 105 49 171 70 286 93 288
57 588 27 852 -85 84 -35 120 -45 162 -45 96 0 157 50 118 97 -7 9 -53 35
-103 59 -167 78 -369 127 -588 144 -146 11 -176 11 -322 -1z"/>
<path d="M4680 3504 c-53 -10 -186 -45 -215 -56 -16 -7 -56 -27 -87 -46 -45
-25 -58 -38 -58 -57 0 -67 138 -92 240 -43 99 47 166 61 285 61 121 -1 202
-17 286 -60 118 -58 262 -20 235 64 -8 23 -116 74 -231 109 -80 23 -110 27
-260 29 -93 2 -181 1 -195 -1z"/>
<path d="M4760 3214 c-71 -31 -87 -67 -50 -112 38 -46 189 -54 256 -13 38 23
44 69 12 98 -48 43 -151 56 -218 27z"/>
<path d="M5762 2728 c-33 -17 -38 -51 -12 -95 l22 -37 -26 -19 c-71 -51 9
-134 103 -107 66 19 78 70 31 124 l-31 34 26 26 c29 29 30 35 12 60 -16 22
-92 31 -125 14z m80 -27 c2 -16 1 -36 -2 -46 -5 -14 -8 -15 -17 -3 -36 46 -38
78 -4 78 13 0 20 -9 23 -29z m-5 -168 c10 -24 9 -34 -1 -48 -31 -42 -76 18
-60 81 6 23 8 24 27 11 12 -8 27 -28 34 -44z"/>
<path d="M3532 2653 l-2 -43 -47 -2 -48 -1 45 -6 c44 -6 45 -7 45 -44 0 -20 3
-37 8 -37 4 0 7 18 7 39 0 40 0 40 43 44 l42 4 -42 1 c-42 2 -42 2 -46 45 l-4
42 -1 -42z"/>
<path d="M3686 2651 l-29 -20 40 6 c34 4 41 2 45 -14 8 -29 -19 -85 -52 -111
-17 -13 -30 -28 -30 -33 0 -5 38 -9 85 -9 l85 0 0 31 0 32 -50 -7 c-59 -8 -64
4 -15 33 19 11 41 32 47 47 11 23 10 28 -11 45 -30 24 -78 24 -115 0z"/>
<path d="M3890 2651 l-25 -21 27 10 c21 7 31 6 43 -6 8 -9 15 -19 15 -22 0
-17 -32 -52 -48 -53 -9 -1 -4 -4 12 -8 62 -13 61 -78 -2 -110 -34 -19 -35 -20
-12 -21 14 0 46 10 70 23 38 19 45 27 48 55 4 37 -17 61 -53 62 -16 0 -13 4
13 20 53 32 40 78 -24 87 -31 4 -45 0 -64 -16z"/>
<path d="M4378 2654 c-53 -28 -64 -89 -23 -129 13 -14 36 -25 50 -25 25 0 25
1 11 22 -20 28 -21 98 -2 124 23 31 39 4 40 -68 1 -75 -17 -124 -53 -143 -20
-11 -22 -14 -8 -15 27 0 72 26 100 59 84 101 -4 236 -115 175z"/>
<path d="M4599 2647 c-81 -54 -54 -160 46 -182 30 -7 30 -7 8 9 -28 21 -39 88
-23 144 12 45 36 56 60 27 15 -18 16 -126 1 -154 -18 -33 14 -27 50 8 48 49
38 125 -21 156 -41 21 -82 19 -121 -8z"/>
<path d="M4829 2650 l-24 -20 26 9 c55 19 80 -31 34 -68 -26 -19 -26 -20 -4
-23 56 -8 48 -81 -11 -110 -35 -18 -35 -18 -7 -18 39 0 106 36 118 64 15 33
-4 65 -43 73 l-31 6 26 14 c41 21 51 49 27 73 -26 26 -80 26 -111 0z"/>
<path d="M5101 2653 l-24 -19 36 4 c20 2 38 -1 42 -8 12 -19 -3 -56 -27 -69
-13 -6 -16 -10 -8 -8 25 7 50 -12 50 -39 0 -38 -18 -64 -53 -79 l-32 -14 27
-1 c57 0 128 47 128 86 0 26 -34 54 -65 54 -16 0 -13 4 13 20 18 11 35 27 39
36 16 42 -84 71 -126 37z"/>
<path d="M6355 2663 c-49 -4 -65 -14 -65 -43 0 -12 12 -14 65 -12 64 4 65 4
65 33 0 16 -3 28 -7 27 -5 -1 -30 -3 -58 -5z"/>
<path d="M4154 2632 c-15 -20 -20 -37 -16 -64 4 -37 4 -38 -30 -38 -31 0 -68
-23 -68 -43 0 -4 23 -7 50 -7 49 0 50 -1 50 -30 0 -25 4 -30 25 -30 21 0 25 5
25 30 0 23 4 30 20 30 15 0 20 7 20 29 0 24 -3 28 -20 24 -19 -5 -20 0 -20 61
0 36 -3 66 -7 65 -5 0 -17 -12 -29 -27z"/>
<path d="M5260 2633 l0 -28 65 3 c69 4 78 -3 44 -34 -33 -30 -71 -101 -67
-126 2 -15 11 -24 27 -26 30 -5 41 12 41 65 0 35 19 90 58 166 2 4 -35 7 -82
7 l-86 0 0 -27z"/>
<path d="M5440 2632 l0 -28 65 4 c36 2 65 0 65 -4 0 -5 -18 -31 -40 -59 -31
-38 -40 -59 -40 -88 0 -36 2 -38 28 -35 25 3 27 7 33 60 6 55 25 117 40 128 3
3 9 15 13 28 7 22 6 22 -78 22 l-86 0 0 -28z"/>
<path d="M5940 2632 l0 -28 73 4 c68 4 97 17 97 44 0 4 -38 8 -85 8 l-85 0 0
-28z"/>
<path d="M6160 2651 c-27 -5 -31 -8 -17 -13 14 -6 17 -20 17 -76 0 -39 -5 -73
-12 -80 -9 -9 -1 -12 38 -12 l49 0 -3 95 c-4 104 1 99 -72 86z"/>
<path d="M6031 2550 c-61 -77 -68 -130 -17 -130 28 0 36 16 36 71 0 22 7 54
16 70 25 50 9 44 -35 -11z"/>
<path d="M6325 2560 c-19 -8 -19 -9 2 -9 28 -1 47 -35 39 -67 -4 -14 -21 -33
-39 -44 -27 -17 -29 -20 -11 -20 34 0 92 28 104 49 14 27 13 66 -2 79 -20 15
-71 22 -93 12z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
